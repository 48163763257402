// On document ready
document.addEventListener('DOMContentLoaded', () => {
  // User icon (only logged in)
  const $userIcon = document.querySelector('.user-icon');
  if ($userIcon) {
    $userIcon.textContent = `${luminateProperties.user.firstName.charAt(0)}${luminateProperties.user.lastName.charAt(0)}`;
  }

  // Bulma Header Dropdowns
  const $navbarDropdowns = Array.prototype.slice.call(document.querySelectorAll('.navbar-item.has-dropdown:not(.is-hoverable)'), 0);
  if ($navbarDropdowns.length > 0) {
    $navbarDropdowns.forEach(el => {
      el.addEventListener('click', event => {
        const $target = event.target;
        $target.classList.toggle('is-active');
      });
    });
  }

  //DIY Mobile Menus
  (function() {
    const $mainNavEls = document.querySelectorAll('[data-menu="main"]');
    const $userNavEls = document.querySelectorAll('[data-menu="user"]');

    const $mainNavButton = document.getElementById('navbarBurger');
    const $userNavButton = document.getElementById('navbarBurgerUser');

    if ($mainNavButton) {
      $mainNavButton.addEventListener('click', () => {
        if ($userNavButton && $userNavButton.classList.contains('is-active')) {
          $userNavEls.forEach(function(el) {
            el.classList.remove('is-active');
          });
        }

        $mainNavEls.forEach(function(el) {
          el.classList.toggle('is-active');
        });
      });
    }

    if ($userNavButton) {
      $userNavButton.addEventListener('click', () => {
        if ($mainNavButton.classList.contains('is-active')) {
          $mainNavEls.forEach(function(el) {
            el.classList.remove('is-active');
          });
        }

        $userNavEls.forEach(function(el) {
          el.classList.toggle('is-active');
        });
      });
    }
  })();

  // Checkbox Switches
  const $checkboxSwitches = Array.prototype.slice.call(document.querySelectorAll('.checkbox.switch'), 0);
  if ($checkboxSwitches.length > 0) {
    $checkboxSwitches.forEach(el => {
      el.addEventListener('click', event => {
        const $el = event.target;
        $el.classList.toggle('active');
        const target = $el.dataset.target; // element to hide/show (optional)
        if (target) {
          document.getElementById(target).classList.toggle('is-hidden');
        }
      });
    });
  }

  // Bulma Modal Triggers
  const $modalTriggers = Array.prototype.slice.call(document.querySelectorAll('.modal-trigger'), 0);
  if ($modalTriggers.length > 0) {
    $modalTriggers.forEach(el => {
      el.addEventListener('click', event => {
        event.preventDefault();
        const target = event.target.dataset.target;
        if (target) {
          document.getElementById(target).classList.toggle('is-active');
        }
      });
    });
  }

  // Bulma Modal Close Buttons
  const $modalCloseElements = Array.prototype.slice.call(document.querySelectorAll('.modal-background, .modal .close'), 0);
  if ($modalCloseElements.length > 0) {
    $modalCloseElements.forEach(el => {
      el.addEventListener('click', event => {
        const $modal = event.target.closest('.modal');
        $modal.classList.remove('is-active');
      });
    });
  }

  //FAQs
  const $faqElements = document.querySelectorAll('[data-faq="question-card"]');
  if ($faqElements.length > 0) {
    $faqElements.forEach(el => {
      const $question = el.querySelector('[data-faq="title"]');
      const $answer = el.lastElementChild;
      const $answers = document.querySelectorAll('[data-faq="content"]');

      $question.addEventListener('click', () => {
        $answers.forEach(ans => {
          if ($answer !== ans) {
            ans.classList.add('is-hidden');
          }
        });
        $answer.classList.toggle('is-hidden');
      });
    });
  }
});
